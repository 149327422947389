/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { getCSS, getCSSVariableValue } from "../../../assets/ts/_utils";
import { observer } from "mobx-react";
import { toJS } from "mobx";
type Props = {
  className: string;
  monthlyDeals: any[];
};

const ChartsWidget3: React.FC<Props> = observer(
  ({ className, monthlyDeals }) => {
    const chartRef = useRef<HTMLDivElement | null>(null);

    const formattedData = monthlyDeals.map((item: any) => {
      return item.deals
        .reduce((acc: any, deal: any) => Number(acc) + Number(deal), 0)
        .toFixed(2);
    });

    function getChartOptions(height: number): ApexOptions {
      const labelColor = getCSSVariableValue("--bs-gray-500");
      const borderColor = getCSSVariableValue("--bs-gray-200");
      const baseColor = getCSSVariableValue("--bs-info");
      const lightColor = getCSSVariableValue("--bs-light-info");

      return {
        series: [
          {
            name: "Zaplaceno",
            data: formattedData.map(Number), // Výsledek: [1500.532, 41, ...]
          },
        ],
        chart: {
          fontFamily: "inherit",
          type: "area",
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {},
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "solid",
          opacity: 1,
        },
        stroke: {
          curve: "smooth",
          show: true,
          width: 3,
          colors: [baseColor],
        },
        xaxis: {
          categories: monthlyDeals.map((item) => item.month),
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              colors: labelColor,
              fontSize: "12px",
            },
          },
          crosshairs: {
            position: "front",
            stroke: {
              color: baseColor,
              width: 1,
              dashArray: 3,
            },
          },
          tooltip: {
            enabled: true,
            formatter: undefined,
            offsetY: 0,
            style: {
              fontSize: "12px",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: labelColor,
              fontSize: "12px",
            },
          },
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          hover: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0,
            },
          },
        },
        tooltip: {
          style: {
            fontSize: "12px",
          },
          y: {
            formatter: (val) =>
              `${val.toLocaleString("cs-CZ", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} Kč`, // Zaokrouhlení a přidání měny
          },
        },
        colors: [lightColor],
        grid: {
          borderColor: borderColor,
          strokeDashArray: 4,
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        markers: {
          strokeColors: baseColor,
          strokeWidth: 3,
        },
      };
    }

    useEffect(() => {
      if (!chartRef.current) {
        return;
      }

      const height = parseInt(getCSS(chartRef.current, "height"));

      const chart = new ApexCharts(chartRef.current, getChartOptions(height));
      if (chart) {
        chart.render();
      }

      return () => {
        if (chart) {
          chart.destroy();
        }
      };
    }, [chartRef]);

    return (
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">
              Zaplacené faktury
            </span>

            <span className="text-muted fw-bold fs-7">
              {/*More than 1000 new records*/}
            </span>
          </h3>

          {/* begin::Toolbar */}
          {/*<div className="card-toolbar" data-kt-buttons="true">*/}
          {/*  <a*/}
          {/*    className="btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1"*/}
          {/*    id="kt_charts_widget_3_year_btn"*/}
          {/*  >*/}
          {/*    Year*/}
          {/*  </a>*/}

          {/*  <a*/}
          {/*    className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1"*/}
          {/*    id="kt_charts_widget_3_month_btn"*/}
          {/*  >*/}
          {/*    Month*/}
          {/*  </a>*/}

          {/*  <a*/}
          {/*    className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4"*/}
          {/*    id="kt_charts_widget_3_week_btn"*/}
          {/*  >*/}
          {/*    Week*/}
          {/*  </a>*/}
          {/*</div>*/}
          {/* end::Toolbar */}
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body">
          {/* begin::Chart */}
          <div
            ref={chartRef}
            id="kt_charts_widget_3_chart"
            style={{ height: "350px" }}
          ></div>
          {/* end::Chart */}
        </div>
        {/* end::Body */}
      </div>
    );
  }
);

export { ChartsWidget3 };
