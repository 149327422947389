import { action, makeAutoObservable, observable } from "mobx";
import SvjStore from "../Svj";

class DashboardStore {
  expectedRevenueThisMonth = 0;
  expectedRevenueThisYear = 0;
  overdueInvoices = 0;
  invoices = [];

  constructor() {
    makeAutoObservable(this, {
      setValue: action,
    });
  }

  setValue(name: string, value: any) {
    // @ts-ignore
    this[name] = value;
  }

  async getData() {
    const stats = await SvjStore.getStats();
    if (stats?.expectedRevenueThisMonth) {
      this.setValue("expectedRevenueThisMonth", stats.expectedRevenueThisMonth);
    }
    if (stats?.expectedRevenueThisYear) {
      this.setValue("expectedRevenueThisYear", stats.expectedRevenueThisYear);
    }
    if (stats?.overdueInvoices) {
      this.setValue("overdueInvoices", stats.overdueInvoices);
    }

    if (stats?.invoices) {
      this.setValue("invoices", stats.invoices);
    }
  }
}
export default new DashboardStore();
