import React, { FC, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import { getTranslation, hasModule } from "../../Helpers/Default";
import { observer } from "mobx-react";
import DashboardStore from "../../Stores/Dashboard";
import CardLayout from "../../Components/CardLayout";
import { NumericFormat } from "react-number-format";
import { row } from "mathjs";
import ApexCharts, { ApexOptions } from "apexcharts";
import { getCSS } from "../../../_metronic/assets/ts/_utils";
import { ChartsWidget3 } from "../../../_metronic/partials/widgets";
import { toJS } from "mobx";

const DashboardWrapper: FC = observer(() => {
  const intl = useIntl();

  // const chartRef = useRef<HTMLDivElement | null>(null);
  //
  // useEffect(() => {
  //   if (!chartRef.current) {
  //     return;
  //   }
  //
  //   const height = parseInt(getCSS(chartRef.current, "height"));
  //   const chart = new ApexCharts(chartRef.current, chartOptions(height));
  //   if (chart) {
  //     chart.render();
  //   }
  //
  //   return () => {
  //     if (chart) {
  //       chart.destroy();
  //     }
  //   };
  // }, [chartRef]);

  useEffect(() => {
    async function getData() {
      DashboardStore.getData();
      // UserStore.getAll()
      // setCommittee(toJS(SvjStore.committees))
    }

    getData();
  }, []);

  // Zpracování dat pro graf
  // const formattedData = DashboardStore.invoices.map((item: any) =>
  //   item.deals.reduce((acc: any, deal: any) => acc + deal, 0).toFixed(2)
  // );

  // const chartOptions = (height: string | number | undefined): ApexOptions => {
  //   return {
  //     series: [
  //       {
  //         name: "Uzavřené dealy",
  //         data: formattedData.map(Number), // Výsledek: [1500.532, 41, ...]
  //       },
  //     ],
  //     chart: {
  //       type: "area",
  //       height: height,
  //     },
  //     xaxis: {
  //       categories: DashboardStore.invoices.map((item: any) => item.month), // ["Leden", "Únor", ...]
  //     },
  //     tooltip: {
  //       y: {
  //         formatter: (val) =>
  //           `${val.toLocaleString("cs-CZ", {
  //             minimumFractionDigits: 2,
  //             maximumFractionDigits: 2,
  //           })} Kč`, // Zaokrouhlení a přidání měny
  //       },
  //     },
  //   };
  // };

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {getTranslation("MENU.DASHBOARD", intl)}
      </PageTitle>

      <div className="row">
        <div className="col-4">
          <CardLayout card_class={"bg-dark"}>
            <i className="fas fa-calendar fs-1"></i>
            <div className="text-white fw-bold fs-2 mb-2 mt-5">
              {
                <NumericFormat
                  value={DashboardStore.expectedRevenueThisYear}
                  name={"value"}
                  displayType={"text"}
                  allowLeadingZeros
                  decimalSeparator={"."}
                  thousandSeparator=" "
                  defaultValue={DashboardStore.expectedRevenueThisYear}
                />
              }{" "}
              Kč
            </div>
            <div className="fw-semibold text-white">
              {getTranslation("DASHBOARD.EXPECTED.TURNOVER.YEAR", intl)}
            </div>
          </CardLayout>
        </div>

        <div className="col-4">
          <CardLayout card_class={"bg-info"}>
            <i className="fas fa-calendar fs-1"></i>
            <div className="text-white fw-bold fs-2 mb-2 mt-5">
              {
                <NumericFormat
                  value={DashboardStore.expectedRevenueThisMonth}
                  name={"value"}
                  displayType={"text"}
                  allowLeadingZeros
                  decimalSeparator={"."}
                  thousandSeparator=" "
                  defaultValue={DashboardStore.expectedRevenueThisMonth}
                />
              }{" "}
              Kč
            </div>
            <div className="fw-semibold text-white">
              {getTranslation("DASHBOARD.EXPECTED.TURNOVER.MONTH", intl)}
            </div>
          </CardLayout>
        </div>
        <div className="col-4">
          <CardLayout card_class={"bg-warning"}>
            <i className="fas fa-file-invoice fs-1 text-white"></i>
            <div className="text-white fw-bold fs-2 mb-2 mt-5">
              {
                <NumericFormat
                  value={DashboardStore.overdueInvoices}
                  name={"value"}
                  displayType={"text"}
                  allowLeadingZeros
                  decimalSeparator={"."}
                  thousandSeparator=" "
                  defaultValue={DashboardStore.overdueInvoices}
                />
              }{" "}
              Kč
            </div>
            <div className="fw-semibold text-white">
              {getTranslation("DASHBOARD.OVERDUE", intl)}
            </div>
          </CardLayout>
        </div>
      </div>
      <div className="row mt-6">
        <div className="col-12">
          <ChartsWidget3
            className="card-xl-stretch mb-xl-8"
            monthlyDeals={toJS(DashboardStore.invoices)}
          />
        </div>
      </div>
    </>
  );
});

export { DashboardWrapper };
